import './Headshots.css';
import { Button } from 'react-bootstrap';
import g1 from './Grad/Grad1.jpg';
import g2 from './Grad/Grad2.jpg';
import g3 from './Grad/Grad3.jpg';
import g4 from './Grad/Grad4.jpg';
import g5 from './Grad/Grad5.jpg';
import g6 from './Grad/Grad6.jpg';
import g7 from './Grad/Grad7.jpg';
import g8 from './Grad/Grad8.jpg';

function Grad({ handleH }) {

    const script = document.createElement("script");
    script.src ="https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return (
      <div className="headshots">
        <div className="headshotsDiv">
          <img alt="" src={g3} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv">
          <img alt="" src={g4} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv">
          <img alt="" src={g1} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h2">
          <img alt="" src={g2} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h2">
          <img alt="" src={g7} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h2">
          <img alt="" src={g8} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h1">
          <img alt="" src={g6} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h1">
          <img alt="" src={g5} className="headshotPhoto" />
        </div>
        <div className="calendly-inline-widget gCalendly" data-url="https://calendly.com/dinaleyphotography/upenn-grad-2022"></div>
      </div>
  );
}

export default Grad;