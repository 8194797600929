import React, { Fragment } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Button } from 'react-bootstrap';
import { useRef, useState } from 'react';
import Home from './Home';
import Headshots from './Headshots';
import BestFriends from './BestFriends';
import Grad from './Grad';
import headshot from './SpringHeadshot.jpg';
import bestfriends from './BestFriends.jpg';
import gradphoto from './Grad.jpg';
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Helmet from "react-helmet";

Amplify.configure(awsconfig);

function App() {
  const [home, setHome] = useState(true);
  const headshots = useRef(false);
  const bestFriends = useRef(false);
  const grad = useRef(false);

  if (headshots.current) {
    return (
      
          <Headshots />
    
    );
  }

  if (bestFriends.current) {
    return (
      <BestFriends />
    );
  }

  if (grad.current) {
    return (
      <Grad />
    );
  }

  return (
    <Router>
        <Routes>
        <Route path="/"  element={<Home />} />
          <Route path="bestfriends" element={<BestFriends />} />
          <Route path="headshots" element={<Headshots />} />
          <Route path="grad" element={<Grad />} />
        </Routes>
    </Router>
  );
}

export default App;