import React, { Fragment } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Button } from 'react-bootstrap';
import { useRef, useState } from 'react';
import Headshots from './Headshots';
import BestFriends from './BestFriends';
import Grad from './Grad';
import headshot from './SpringHeadshot.jpg';
import bestfriends from './BestFriends.jpg';
import gradphoto from './Grad.jpg';
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Helmet from "react-helmet";

Amplify.configure(awsconfig);

function App() {
  return (
        <div>
          <div className="categories">
            <div className="gradDiv">
                <a href="/grad">
                  <img alt="" src={gradphoto} className="gradCoverPhoto" />
                  <div className="overlay">
                    <div className="text">Grad</div>
                  </div>
                </a>
                <Button className="mobilebutton" variant="dark" size="sm" href="/grad">Grad</Button>
            </div>

            <div className="bestFriendsDiv">
                <a href="/bestfriends"><img alt="" src={bestfriends} className="bestFriendsCoverPhoto" />
                <div className="overlay">
                    <div className="text">Best Friends</div>
                  </div>
                </a>
                <Button className="mobilebutton" variant="dark" size="sm" href="/bestfriends">Best Friends</Button>
            </div>

            <div className="headshotDiv">
              <a href="/headshots"><img alt="" src={headshot} className="headshotCoverPhoto" />
              <div className="overlay">
                    <div className="text">Headshots</div>
                  </div>
              </a>
              <Button className="mobilebutton" variant="dark" size="sm" href="/headshots">Headshots</Button>
            </div>
          </div>
        </div>
  );
}

export default App;