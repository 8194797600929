import './Headshots.css';
import { Button } from 'react-bootstrap';
import h1 from './Headshots/Headshots1.jpg';
import h2 from './Headshots/Headshots2.jpg';
import h3 from './Headshots/Headshots3.jpg';
import h4 from './Headshots/Headshots4.jpg';
import h5 from './Headshots/Headshots5.jpg';
import h6 from './Headshots/Headshots6.jpg';
import h7 from './Headshots/Headshots7.jpg';
import h8 from './Headshots/Headshots8.jpg';

function Headshots() {

    const script = document.createElement("script");
    script.src ="https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

  return (
      <div className="headshots">
        <div className="headshotsDiv">
          <img alt="" src={h1} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv">
          <img alt="" src={h6} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv">
          <img alt="" src={h4} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h2">
          <img alt="" src={h5} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h2">
          <img alt="" src={h7} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h2">
          <img alt="" src={h3} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h1">
          <img alt="" src={h2} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h1">
          <img alt="" src={h8} className="headshotPhoto" />
        </div>
        <div className="greenColor">
          <div className="calendly-inline-widget hCalendly" data-url="https://calendly.com/dinaleyphotography/april-3-headshots"></div>
          <p className="specify">If you want to book headshots for your club, DM me! This link is for individual headshots on April 3rd.</p>
        </div>
      </div>
  );
}

export default Headshots;