import bf1 from './BestFriendsPhotos/bf1.jpg';
import bf3 from './BestFriendsPhotos/bf3.jpg';
import bf4 from './BestFriendsPhotos/bf4.jpg';
import bf5 from './BestFriendsPhotos/bf5.jpg';
import bf7 from './BestFriendsPhotos/bf7.jpg';
import bf10 from './BestFriendsPhotos/bf10.jpg';
import bf11 from './BestFriendsPhotos/bf11.jpg';
import bf13 from './BestFriendsPhotos/bf13.jpg';
import './Headshots.css';

function BestFriends({ handleH }) {

    const script = document.createElement("script");
    script.src ="https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return (
      <div className="headshots">
        <div className="headshotsDiv">
          <img alt="" src={bf1} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv">
          <img alt="" src={bf7} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv">
          <img alt="" src={bf3} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h2">
          <img alt="" src={bf10} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h2">
          <img alt="" src={bf11} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h2">
          <img alt="" src={bf5} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h1">
          <img alt="" src={bf13} className="headshotPhoto" />
        </div>
        <div className="headshotsDiv" id="h1">
          <img alt="" src={bf4} className="headshotPhoto" />
        </div>
        <div className="calendly-inline-widget gCalendly" data-url="https://calendly.com/dinaleyphotography/best-friends-shoot"></div>
      </div>
  );
}

export default BestFriends;